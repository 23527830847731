.ConfigContent {
  height: 100%;
}

.table-title {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F8F8F8;
  border: 1px solid #dee2e6 !important;
  border-bottom: 0px;
  align-items: center;
}

.table-title input {
  height: 2em;
}

.table-title label {
  margin: 0px;
  margin-right: 5px;
  margin-left: 10px;
  align-self: center;
  font-weight: bold;
}

.table-title button {
  width: 15em;
}
.table-container {
  height: 60vh;
}

select {
  height: 2em;
  min-width: 10em;
  max-width: 20em;
  margin-right: 1em;
}

input {
  margin-right: 1em;
}

.table-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.table-filters button {
  width: 10em;
}

.table-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F8F8F8;
  border: 1px solid #dee2e6 !important;
  border-top: 0px;
  padding: 5px;
}

.info {
  display: flex;
  flex-direction: row;
}

.info p {
  margin: 0px;
  margin-right: 10px;
  align-self: center;
}

.table-bottom button {
  width: 15em;
}
