.App {
  text-align: center;
  height: 100vh;
  margin: 30px;
  padding: 15px;
  font-family: "Overpass", sans-serif;
}

.App-header {
  /* background-color: #d20633; */
  background-image: url("./assets/OrthoPlus_banner.png");
  background-size: cover;
  min-height: 100px;
  margin: 0;
  padding-left: 32px;
  font-size: 14px;
  text-align: center;
  background-color: unset;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 0;
}

.App-header img {
    height: 26px;
}

.App-header .header-links {
    vertical-align: middle;
    text-decoration: none;
    font-family: Montserrat;
    margin: 0;
    padding-right: 7px;
    font-size: 14px;
    text-align: center;
    background-color: unset;
    text-transform: uppercase;
}

.App-header .header-links a {
    padding: 7px;
    color: white;
}

.header-options button {
  margin-right: 10px;
  width: 100px;
}

.App-content {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
