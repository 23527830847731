table {
  display: block;
  width: 100%;
  height: 100%;
}

.table-responsive,
.table-message-box {
  min-height: 100%;
  width: 100%;
  height: 100%;
  border: 1px solid #dee2e6 !important;
}

.table-message-box {
    padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
