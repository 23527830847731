.ConfigForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1em;
  padding: 8px;
  border: 1px solid #dee2e6 !important;
  background-color: #f8f8f8;
  align-items: center;
}

.form-field {
  width: fit-content;
  height: fit-content;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.form-step {
  flex-wrap: wrap;
  min-width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
}

.form-field label,
.form-field-date label {
  margin: 0px;
  margin-right: 5px;
  margin-left: 10px;
  align-self: center;
  font-weight: bold;
}

.form-field input,
.form-field select {
  height: 2em;
  min-width: 10em;
  max-width: 20em;
  margin-right: 1em;
}

.form-field-date {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 2em;
  min-width: 20em;
  max-width: 24em;
  margin-right: 1em;
}

.form-field-date input {
  margin-right: 0;
}

.form-field-date .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  height: 100%;
  margin: 0px;
}

.form-field-date .checkbox label {
  margin: 0;
}

.form-field-date .checkbox input {
  margin: 0.5em;
}

.form-submit {
  min-width: 5em;
  max-width: 10em;
  height: 2em;
}
